import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ContentCard from '../components/Layout/ContentCard'
import RecallYears from '../components/RecallYears'
import Layout from '../components/Layout/Layout'
import SEO from "../components/seo"

export const query = graphql`
  query {
    allNodeRecalls (
      sort: {
        fields: [field_recall_date]
        order: DESC
      }) {
      edges {
        node {
          id
          drupal_internal__nid
          title
          field_teaser
          field_phone
          displayDate: field_recall_date(formatString: "MM/DD/Y")
          field_website {
            uri
          }
          body {
            value
          }
          path {
            alias
          }
          relationships {
            featuredImage: field_image {
              localFile {
                childImageSharp {
                  fixed(width: 100, height: 100, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }`

const RecallPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Bicycle Recalls" />
    <section>
      <div className="container mx-auto px-8">
        <div className="page-title">Bicycle Recalls</div>
        <RecallYears />
        {data.allNodeRecalls.edges.map(recall => (
          <>
            <ContentCard data={recall.node} location={location} />
          </>
        ))}
      </div>
    </section>
  </Layout>
)

RecallPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default RecallPage
