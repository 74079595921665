import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"

const RecallYears = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allNodeRecalls(sort: {fields: field_recall_date, order: DESC}) {
        edges {
          node {
            year: field_recall_date(formatString: "YYYY")
          }
        }
      }
    }
  `)
  console.log(data.allNodeRecalls.edges);
  const uniqueYears = [...new Set(data.allNodeRecalls.edges.map(item => item.node.year))]
  // const uniqueYears = [...new Map(data.allNodeRecalls.edges.map(item => [item.node[key], item])).values()]
  // const uniqueYears = data.allNodeRecalls.edges.filter((v, i, a) => a.findIndex(t => (t.node.year === v.node.year)) === i)
  // const uniqueYears = data.allNodeRecalls.edges.filter((item, index, self) => self.findIndex(t => t.year === item.year) === index)
  
  return (
    <section className="container mx-auto">
      <ul className="list-none ml-0">
      {uniqueYears.map(year => (
        <>
          <li className="inline mr-5">
            <Link to={'/recalls/' + year}>
              {year}
            </Link>
          </li>
        </>
      ))}
      </ul>
    </section>
  )
}

export default RecallYears
